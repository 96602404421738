<script setup lang="ts">
const emit = defineEmits<{
  onSocialClick: [social: string]
}>()

const { currentWebsite } = useWebsite()

const paymentMethods = await usePaymentMethods()

const brandSocials = ref()
brandSocials.value = await useBrandSocials(currentWebsite.value)

watch(currentWebsite, async () => {
  brandSocials.value = await useBrandSocials(currentWebsite.value)
})
</script>
<template>
  <div class="flex flex-col justify-center lg:flex-row lg:justify-between">
    <div
      class="positive-padding gap-md gap-y-xs lg:py-lg py-md flex h-full flex-wrap items-center md:gap-y-0"
    >
      <span class="text-medium-6 block w-full md:w-auto lg:hidden">
        {{ $ts(`footer.follow`) }}
      </span>
      <ul
        class="gap-md sm:gap-y-xs flex flex-wrap items-center md:gap-y-0"
        aria-label="$ts('accessibility.socialLinks')"
      >
        <FooterSocialIcon
          v-for="brandSocial in brandSocials"
          :key="brandSocial.social"
          v-bind="brandSocial"
          @click="emit('onSocialClick', brandSocial.social)"
        />
      </ul>
    </div>

    <ul
      class="positive-padding lg:py-lg gap-md py-md bg-primitives-off-white lg:bg-neutral-white flex h-full items-center overflow-auto"
      aria-label="$ts('accessibility.paymentMethods')"
    >
      <FooterPaymentMethodsIcon
        v-for="paymentMethod in paymentMethods"
        :key="paymentMethod"
        :icon="paymentMethod"
      />
    </ul>
  </div>
</template>
