import type { BrandSocials } from '@integration-layer/ampliance/schemas/brand-socials-schema.localized'

export const useBrandSocials = async (brand: string) => {
  const deliveryKey = 'brand-socials-' + brand
  const localePath = useLocalePath()

  const query = {
    locale: localePath(''),
    vse: useRoute().query.vse,
    contentId: useRoute().query.contentId,
    deliveryKey,
  }

  const response = await useFetch('/api/cms/getBrandSocials', {
    query,
    getCachedData: (key, nuxtApp) => nuxtApp.payload.data[key],
    dedupe: 'defer',
  })

  return response?.data?.value?.content?.socialItem as BrandSocials[]
}
